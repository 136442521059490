import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "smsfollowupTemplateList",

  data() {
    return {
      smsfollowupTemplateList: [],
      searchText: "",
      showLoader: false,
      selected: "",
      selectedId: 0,
      selectedRows: [],
      sortColumn: "name",
      sortDirection: "asc",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.smsfollowupTemplateList.length > 0) {
        return (
          _.where(this.smsfollowupTemplateList, { isSelected: true }).length ===
          this.smsfollowupTemplateList.length
        );
      }
      return false;
    },
    hasSmsFollowupTempleShowPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsFollowupTemplate-index";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSmsFollowupTempletAddPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsFollowupTemplate-store";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSmsFollowupTempletDeletePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsFollowupTemplate-destroy";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSmsFollowupTempletUpdatePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsFollowupTemplate-update";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSmsFollowupTempletCopyPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsFollowupTemplate-copy";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSmsFollowupTempletHideShowPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadSmsFollowupTemplate-updatestatus";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    updateStaticTemplateStatus(SST, event) {
      let _vm = this;
      let _msg =
        SST.is_display == true
          ? "Are you sure you want to Deactivate this Static Template?"
          : "Are you sure you want to Active this Static Template?";
      let _isDisplay = SST.is_display == true ? 1 : 0;
      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post(
              "/sms-followup-templates-update-status/id?id=" +
                SST.id +
                "&is_display=" +
                _isDisplay
            )
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById("regions-checkbox-" + SST.id).checked =
                SST.is_display;
            });
        })
        .catch(function () {
          document.getElementById("regions-checkbox-" + SST.id).checked =
            SST.is_display;
        });
    },
    checkUncheckedList(event) {
      this.smsfollowupTemplateList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    copyStaticTemplate(smsTemplate, event) {
      let _vm = this;
      let _msg = "Are you sure you want to Duplicate this Static template ?";

      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post("/sms-followup-templates/copy/" + smsTemplate.id)
            .then(function (response) {
              _vm.getAll();
              _vm.selectedId = 0;
            });
        })
        .catch(function () {
          _vm.getAll();
        });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific ID is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Iterate through smsfollowupTemplateList to find selected IDs
        this.smsfollowupTemplateList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      _vm.axios
        .post("/sms-followup-templates-deletes/", { ids: ids })
        .then(function () {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send a GET request to retrieve tags
      this.axios
        .get("/sms-followup-templates" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.smsfollowupTemplateList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Add all checkbox IDs to selectedRows
          this.selectedRows = this.smsfollowupTemplateList.map(
            (item) => "checkbox" + item.id
          );
          this.smsfollowupTemplateList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Clear selectedRows and set isSelected to false for all elements
          this.selectedRows = [];
          this.smsfollowupTemplateList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the checked checkbox ID to selectedRows
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the unchecked checkbox ID from selectedRows
          this.selectedRows = this.selectedRows.filter((item) => {
            // Filter out the unchecked checkbox ID
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
